<template>
  <svg
    class="my-2 my-md-0"
    ref="panda"
    id="main-panda"
    width="305"
    height="318"
    viewBox="0 0 305 318"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_9_629)">
      <path
        d="M152.441 306.515C230.005 306.515 292.882 243.389 292.882 165.518C292.882 87.6477 230.005 24.5211 152.441 24.5211C74.8776 24.5211 12 87.6477 12 165.518C12 243.389 74.8776 306.515 152.441 306.515Z"
        fill="white"
      />
      <path
        d="M152.441 302.575C227.837 302.575 288.957 241.212 288.957 165.518C288.957 89.8243 227.837 28.4622 152.441 28.4622C77.0456 28.4622 15.9254 89.8243 15.9254 165.518C15.9254 241.212 77.0456 302.575 152.441 302.575Z"
        stroke="#FFD400"
        stroke-width="9"
      />
    </g>
    <g filter="url(#filter1_d_9_629)">
      <path
        d="M203.221 150.637C203.932 153.195 208.566 168.972 221.785 173.902C238.074 179.975 254.032 164.585 259.328 159.475C268.742 150.399 271.909 140.555 273.779 134.744C275.987 127.884 278.113 120.933 274.615 114.567C274.135 113.695 271.502 108.89 266.078 107.302C261.393 105.933 257.53 107.71 255.289 108.741C249.741 111.296 246.996 116 245.292 118.878C232.298 140.829 231.496 145.957 226.039 148.6C222.535 150.302 216.546 151.247 206.082 146.277L203.221 150.637Z"
        fill="#312F2D"
      />
      <path
        d="M163.498 258.084C163.194 265.846 162.915 271.941 162.553 277.322C162.417 279.374 162.207 282.264 164.053 284.529C165.973 286.883 169.088 287.316 170.631 287.413C192.564 290.623 204.097 290.127 205.228 285.925C205.648 284.371 204.635 282.305 202.192 279.727C200.916 278.554 199.551 277.482 198.11 276.52C196.449 275.413 194.687 274.466 192.848 273.691C196.842 267.27 200.324 260.542 203.261 253.569C212.371 231.963 214.858 211.655 215.389 197.57C212.933 208.798 169.35 255.444 163.498 258.084Z"
        fill="#312F2D"
      />
      <path
        d="M145.712 258.084C145.909 266.316 146.21 272.413 146.586 277.426C146.735 279.383 146.978 282.267 145.153 284.529C143.258 286.886 140.13 287.316 138.578 287.413C116.641 290.623 105.107 290.127 103.978 285.925C103.559 284.371 104.571 282.305 107.015 279.727C108.29 278.553 109.656 277.48 111.099 276.52C112.76 275.413 114.521 274.466 116.359 273.691C112.366 267.269 108.884 260.541 105.946 253.569C96.8354 231.963 94.3514 211.655 93.8169 197.57C96.2766 208.798 139.86 255.444 145.712 258.084Z"
        fill="#312F2D"
      />
      <path
        d="M154.934 259.978C188.505 259.978 215.72 229.387 215.72 191.652C215.72 153.917 188.505 123.326 154.934 123.326C121.363 123.326 94.1479 153.917 94.1479 191.652C94.1479 229.387 121.363 259.978 154.934 259.978Z"
        fill="white"
        stroke="#313438"
        stroke-width="6.92465"
        stroke-miterlimit="10"
      />
      <path
        d="M200.512 151.917C194.439 162.222 181.925 176.045 168.432 189.261C155.897 201.542 141.041 205.993 131.172 211.749C131.059 212.258 133.042 218.734 134.512 220.975C135.906 223.109 138.515 224.24 141.217 223.911C148.928 221.33 156.243 217.685 162.954 213.081C168.841 209.02 174.217 204.26 178.967 198.905C194.791 181.487 202.416 159.536 205.347 149.869L200.512 151.917Z"
        fill="#6E2E2B"
      />
      <path
        d="M140.276 222.654C140.466 231.315 139.386 239.956 137.072 248.3C135.051 255.601 132.101 262.61 128.296 269.154C126.583 272.102 124.136 273.541 121.658 274.611C119.301 275.617 116.723 275.985 114.181 275.678C103.88 274.965 93.7441 272.695 84.1176 268.944C71.1477 263.864 63.8049 259.587 62.6965 253.044C61.6701 246.947 67.0481 242.804 73.0821 234.276C78.2992 226.901 84.6217 215.401 88.0562 198.136L140.276 222.654Z"
        fill="#D5664D"
        stroke="#6E2E2B"
        stroke-width="11.9336"
        stroke-miterlimit="10"
      />
      <path
        d="M140.689 223.719C140.385 222.002 139.86 220.78 138.259 218.347C137.579 217.325 136.987 216.386 136.477 215.481C134.895 212.676 131.967 206.987 126.72 203.149C126.72 203.149 113.395 191.216 91.5728 190.539C90.2317 190.51 88.8922 190.649 87.5856 190.954C85.8671 191.449 84.2368 192.213 82.7541 193.216C79.8662 195.197 66.7961 207.036 70.9898 218.411C77.5249 236.133 97.3487 249.31 114.297 245.91C121.39 244.487 127.355 240.349 127.713 240.087C134.825 235.057 139.204 226.825 140.689 223.719Z"
        fill="#6E2E2B"
      />
      <path
        d="M106.848 149.747C104.418 150.768 89.3712 157.311 86.1128 171.088C82.0952 188.06 99.2558 202.085 104.941 206.74C115.062 215.011 125.177 216.969 131.151 218.124C138.202 219.49 145.335 220.761 151.193 216.496C151.998 215.911 156.425 212.704 157.333 207.106C158.117 202.271 155.888 198.637 154.6 196.53C151.399 191.31 146.401 189.152 143.364 187.804C120.082 177.533 114.91 177.359 111.63 172.256C109.523 168.969 107.859 163.11 111.494 152.085L106.848 149.747Z"
        fill="#312F2D"
      />
      <path
        d="M221.9 65.6287C222.227 65.9521 222.632 66.1842 223.076 66.3018C223.52 66.4194 223.986 66.4186 224.43 66.2994C229.219 65.0799 239.808 61.4458 247.867 51.0739C252.577 44.9644 255.574 37.7013 256.546 30.0375C256.546 29.9674 256.564 29.8973 256.567 29.8241C256.871 25.126 256.425 16.4096 246.989 7.72067C237.554 -0.968265 226.118 -0.0627864 224.311 0.110992C216.798 0.996118 209.681 3.97068 203.762 8.69932C193.965 16.5834 190.658 26.9095 189.556 31.6625C189.433 32.1529 189.438 32.6666 189.57 33.1546C189.702 33.6425 189.956 34.0883 190.309 34.4491L221.9 65.6287Z"
        fill="#312F2D"
      />
      <path
        d="M114.512 33.7814C114.656 33.3232 114.678 32.8351 114.575 32.3659C114.472 31.8966 114.248 31.4628 113.926 31.1076C105.12 21.1656 88.6029 16.562 88.6029 16.562C84.3514 15.562 81.1355 14.8364 76.711 15.1962C72.5441 15.5726 68.4834 16.7254 64.7372 18.5955C63.1976 19.3333 60.5799 20.562 58.0382 22.7174C54.5156 25.7052 50.917 30.4247 48.8308 38.0496C45.293 51.0007 51.6064 61.1896 52.6237 62.8024C57.0426 69.3772 63.2042 74.5811 70.4128 77.8267C82.4049 83.1803 93.5558 81.3327 98.4905 80.0919C99.0068 79.9727 99.4838 79.7219 99.8754 79.3635C100.267 79.0051 100.56 78.5514 100.725 78.0462C105.321 63.2902 109.917 48.5353 114.512 33.7814Z"
        fill="#312F2D"
      />
      <path
        d="M134.615 11.3729C129.623 12.7022 97.7313 21.7387 77.78 53.2384C74.8677 57.8389 48.4937 100.775 68.2477 132.506C77.1878 146.866 91.6305 152.628 101.87 156.689C127.355 166.826 150.179 162.53 169.65 158.865C187.886 155.436 212.164 150.548 233.449 131.067C241.572 123.628 249.744 116.128 253.418 103.781C262.75 72.3784 233.552 41.4214 229.31 37.0556C205.912 12.9766 168.803 2.26934 134.615 11.3729Z"
        fill="white"
        stroke="#313438"
        stroke-width="6.92465"
        stroke-miterlimit="10"
      />
      <path
        d="M208.891 40.8668C204.639 39.132 196.592 37.3363 188.848 41.5375C180.148 46.2661 177.658 55.9276 176.85 59.0709C172.474 76.0341 183.516 90.1864 184.627 91.5644C188.644 96.337 193.715 100.102 199.437 102.558C199.867 102.743 200.312 102.889 200.768 102.994C209.465 105.046 220.928 103.817 229.811 96.8815C233.424 94.0583 237.557 90.8297 239.325 85.0127C242.404 74.8786 235.827 65.019 231.891 59.1196C229.361 55.2996 221.77 46.1198 208.891 40.8668Z"
        fill="#383634"
      />
      <path
        class="eye"
        d="M209.319 64.4549C210.808 66.1872 211.814 68.2847 212.234 70.5341C212.455 71.7199 212.506 72.9313 212.386 74.1316C212.055 75.5237 211.545 76.8663 210.868 78.1255C210.239 79.3145 208.781 81.9669 205.869 83.7961C200.743 87.0186 195.068 85.2077 194.545 85.0309C193.464 84.6681 189.483 83.2565 187.202 79.0553C184.721 74.4822 186.07 69.9792 186.431 68.7658C186.823 67.4548 187.883 63.9122 191.323 61.6104C195.392 58.8848 199.884 59.8421 201.603 60.208C203.106 60.525 206.586 61.3177 209.319 64.4549Z"
        fill="black"
      />
      <path
        d="M198.004 65.2932C198.527 65.9009 198.879 66.6375 199.024 67.4274C199.102 67.8414 199.119 68.2645 199.076 68.6835C198.965 69.1717 198.788 69.6425 198.551 70.0828C198.154 70.8879 197.55 71.572 196.802 72.0645C195.01 73.1926 193.024 72.5584 192.854 72.4975C191.767 72.1481 190.85 71.4017 190.285 70.406C190.004 69.8543 189.836 69.252 189.79 68.6343C189.744 68.0165 189.822 67.3958 190.018 66.8085C190.273 65.7966 190.879 64.9092 191.727 64.3054C193.151 63.3512 194.721 63.6957 195.323 63.8146C196.354 63.9971 197.297 64.5169 198.004 65.2932Z"
        fill="white"
        stroke="#1D1D1B"
        stroke-width="0.735172"
        stroke-miterlimit="10"
      />
      <path
        d="M93.6651 56.6592C94.9345 55.6165 102.529 49.5739 113.082 51.2751C120.977 52.5495 127.78 57.8513 131.302 64.4853C133.285 68.214 133.747 71.4273 134.293 75.2657C134.84 79.1041 135.994 87.918 132.049 97.7381C129.614 103.505 125.871 108.62 121.117 112.677C120.757 112.979 120.373 113.251 119.969 113.491C112.241 118.064 100.877 120.341 90.2852 116.393C85.9791 114.787 81.0505 112.948 77.619 107.93C71.6428 99.1862 75.0105 87.8083 77.0117 80.9852C78.3478 76.5828 82.8695 65.5463 93.6651 56.6592Z"
        fill="#383634"
      />
      <path
        class="eye"
        d="M127.412 74.0491C128.903 75.7812 129.91 77.8787 130.331 80.1283C130.549 81.3134 130.599 82.5236 130.479 83.7228C130.157 85.1158 129.653 86.4598 128.979 87.7197C128.351 88.9057 126.89 91.5612 123.978 93.3904C118.855 96.6129 113.179 94.802 112.654 94.6252C111.573 94.2593 107.591 92.8477 105.311 88.6496C102.83 84.0765 104.178 79.5735 104.54 78.3601C104.931 77.0461 105.991 73.5065 109.432 71.2016C113.501 68.4791 117.992 69.4333 119.711 69.7992C121.205 70.1193 124.688 70.912 127.412 74.0491Z"
        fill="black"
      />
      <path
        d="M122.468 75.7047C123.014 76.3025 123.383 77.0418 123.531 77.8389C123.61 78.2538 123.625 78.6784 123.577 79.098C123.451 79.5908 123.26 80.0645 123.009 80.5065C122.571 81.3282 121.925 82.0191 121.135 82.5095C119.228 83.6559 117.13 83.04 116.936 82.979C115.808 82.6528 114.843 81.9128 114.233 80.9059C113.322 79.3114 113.841 77.726 113.978 77.2992C114.266 76.266 114.912 75.37 115.8 74.7718C117.318 73.8023 118.976 74.1255 119.614 74.2474C120.698 74.4075 121.701 74.9193 122.468 75.7047Z"
        fill="white"
        stroke="#1D1D1B"
        stroke-width="0.75796"
        stroke-miterlimit="10"
      />
      <path
        d="M147.825 110.335C147.753 109.481 147.881 108.622 148.199 107.826C148.356 107.503 148.547 107.196 148.767 106.912C149.89 105.503 152.344 104.168 159.599 103.058C159.599 103.058 170.974 100.86 173.908 103.363C174.591 103.93 175.063 104.712 175.25 105.582C175.554 106.863 175.128 108.528 174.861 109.21C174.205 110.899 170.367 117.412 162.778 118.326C157.045 119.018 152.927 116.058 151.266 114.863C151.266 114.863 148.193 112.656 147.825 110.335Z"
        fill="#252321"
      />
      <path
        d="M163.926 136.887C163.226 137.271 162.495 137.592 161.739 137.848C161.366 137.985 160.989 138.082 160.61 138.198C160.23 138.314 159.848 138.384 159.465 138.473C157.926 138.784 156.358 138.93 154.788 138.909C148.414 138.781 142.317 136.265 137.695 131.857C136.563 130.764 135.55 129.554 134.673 128.247C133.777 126.934 133.08 125.496 132.602 123.979L132.884 123.723C133.878 124.775 134.928 125.771 136.03 126.707C139.313 129.535 143.025 131.816 147.026 133.463C148.354 134.016 149.712 134.494 151.093 134.893C152.474 135.296 153.88 135.61 155.295 135.875C158.137 136.375 161.021 136.587 163.905 136.509L163.926 136.887Z"
        fill="#252321"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_9_629"
        x="0.425415"
        y="11.9622"
        width="304.031"
        height="305.112"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="-1" />
        <feGaussianBlur stdDeviation="5.5" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_9_629"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_9_629"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_d_9_629"
        x="43.7645"
        y="0"
        width="236.706"
        height="297.498"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_9_629"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_9_629"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
</template>

<script>
export default {
  // mounted() {
  //   let eyes = document.querySelectorAll(".eye");
  //   let axes = { x: 0, y: 0 };
  //   addEventListener("mousemove", (e) => {
  //     axes.x = (e.clientX * 100) / window.innerWidth + "%";
  //     axes.y = (e.clientY * 100) / window.innerWidth + "%";
  //     console.log(eyes);
  //     for (let i = 0; i < 2; i++) {
  //       eyes[i].style.left = axes.x / 2;
  //       eyes[i].style.top = axes.y / 2;
  //       eyes[i].style.transform = "translate(" + axes.x + "," + axes.y + ")";
  //     }
  //     // console.log(axes.x > panda.offsetLeft);
  //   });
  // },
};
</script>
